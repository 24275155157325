import { ReactNode } from "react";
import { makeStyles, Modal, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles({
    paper: {
        position: "absolute",
        width: "100%",
        minHeight: 440,
        borderRadius: 4,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF",
        boxShadow: "5px 5px rgba(0,0,0,0.07)",
        padding: "15px 22px",
        border: "solid 1px rgba(0,0,0,0.07)",
        verticalAlign: "top",
        maxHeight: "100%",
    },
    button: {
        width: 30,
        height: 30,
        minWidth: 30,
    },
    closeButton: {
        position: "absolute",
        top: 15,
        right: 15,
    },
    icon: {
        position: "relative",
        width: 24,
        height: 24,
        verticalAlign: "middle",
        display: "inline-block",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        verticalAlign: "top",
        height: "100%",
        width: "100%",
    },
});

type IProps = {
    open: boolean;
    width?: number;
    height?: number;
    children?: ReactNode;
    handleClose: () => void;
};

export default function ModalWindow(props: IProps) {
    const classes = useStyles();
    const { open } = props;

    const body = (
        <div
            className={classes.paper}
            style={{
                width: props.width ? props.width : "",
                height: props.height ? props.height : "",
            }}
        >
            <div className={classes.content}>
                {props.children}
                <div className={classes.closeButton}>
                    <Button
                        className={classes.button}
                        variant="text"
                        onClick={(e) => {
                            props.handleClose();
                        }}
                    >
                        <Close className={classes.icon} />
                    </Button>
                </div>
            </div>
        </div>
    );
    return (
        <Modal
            open={open}
            onClose={props.handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            {body}
        </Modal>
    );
}
