import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import AssetList from "AssetList";
import AssetTitle from "AssetTitle";

const useStyles = makeStyles({
    root: {
        position: "absolute",
        display: "table",
        background: "#E6E6E6",
        width: 250,
        transition: "height 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        userSelect: "none",
        borderRadius: 15,
        left: 10,
        top: 10,
    },
});

type IProps = {
    isLoaded: boolean;
};

export default function Panel(props: IProps) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState("");
    const [feature, setFeature] = useState(null);
    return (
        <div className={classes.root} style={{ display: props.isLoaded ? "block" : "none" }}>
            {page === 0 ? (
                <AssetList filter={filter} setPage={setPage} setFeature={setFeature} setFilter={setFilter} />
            ) : (
                <AssetTitle feature={feature} setPage={setPage} setFeature={setFeature} setFilter={setFilter} />
            )}
        </div>
    );
}
