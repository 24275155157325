import { useRef } from "react";
import EmbeddedViewer from "@voxelfarm/voxelfarm-react-viewer";
import EventsEmitter from "events/EventsEmitter";
import ProgressBar from "components/ProgressBar";
import json from "assets/FeatureCollection.json";
import ViewerActions from "actions/ViewerActions";

const config = {
    projectId: "E34FA3224F414F0CA45D974F0B5AF330",
    viewId: "1C3FE397688343DDAEF2D56565D66212",
    restUrl: "https://intellisense.voxelfarm.com/",
    streamingUrl: "wss://intellisense-ws.voxelfarm.com",
};

type IProps = {
    isLoaded: boolean;
    onNewScene: () => void;
};

export default function Viewer(props: IProps) {
    const viewer = useRef();
    const { isLoaded, onNewScene } = props;
    const loadGeoJSONLayer = () => {
        json.features.forEach((feature: any) => {
            //feature["properties"]["icon"] = defaultIcon;
            feature["properties"]["onClick"] = (id: string) => {
                ViewerActions.geoClick(json.features, id);
            };
        });
        const commentId = 0;
        if (viewer && viewer.current && viewer.current !== null) {
            var current: any = viewer.current;
            if (current) {
                ViewerActions.viewer = current;
                var curViewer = current.getViewer();
                if (curViewer) {
                    curViewer.loadGeoJSONLayer(commentId, json, true);
                }
            }
        }
        //console.log("loadGeoJSONLayer", json);
    };

    const onBusy = (isBusy: boolean) => {
        if (!isBusy) {
            loadGeoJSONLayer();
        }
        EventsEmitter.emit("busy", {
            isBusy: isBusy,
        });
    };
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "rgb(100, 100, 100)",
                overflowY: "hidden",
            }}
        >
            {isLoaded ? <ProgressBar /> : ""}
            <EmbeddedViewer
                ref={viewer}
                projectId={config.projectId}
                streamingUrl={config.streamingUrl}
                restUrl={config.restUrl}
                style={{ width: "100%", height: "100%" }}
                showControlsInView={true}
                onNewScene={onNewScene}
                onBusy={onBusy}
                viewId={config.viewId}
                enableLoadAttributes={true}
            />
        </div>
    );
}
