import { useCallback, useEffect, useState } from "react";
import { makeStyles, Input, Typography, Button } from "@material-ui/core";
import json from "assets/FeatureCollection.json";
import ViewerActions from "actions/ViewerActions";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles({
    root: {},
    header: {
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
        height: 50,
        width: "94%",
    },
    title: {
        fontFamily: "Arial",
        fontSize: 14,
        fontWeight: "bold",
        color: "darkblue",
        paddingLeft: 20,
    },
    label: {
        cursor: "pointer",
    },
    icon: {
        width: 18,
        height: 18,
        position: "absolute",
        right: 10,
    },
    centerMiddle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    search: {
        position: "relative",
        width: "80%",
        height: 30,
        border: "solid 1px darkblue",
        borderRadius: 5,
        top: 10,
        bottom: 20,
        backgroundColor: "white",
        fontFamily: "Arial",
        fontSize: 14,
        padding: 10,
    },
});

type IProps = {
    filter: string;
    setPage: (pageNum: number) => void;
    setFeature: (feature: any) => void;
    setFilter: (filter: any) => void;
};

export default function AssetList(props: IProps) {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);
    const [assets, setAssets] = useState(json.features);
    const { filter, setFeature, setPage, setFilter } = props;
    useEffect(() => {
        var updateFeature = (feature: any) => {
            setPage(1);
            setFeature(feature);
            setFilter("");
        };
        ViewerActions.on("setFeature", updateFeature);
        return () => {
            ViewerActions.removeEventListener("setFeature", updateFeature);
        };
    }, [setFeature, setFilter, setPage]);
    const handleClick = (evt: any) => {
        setPage(1);
    };
    const handleLabelClick = (feature: any) => {
        var id = feature["properties"]["id"];
        setFeature(feature);
        ViewerActions.geoClick(json.features, id);
    };
    const toggleCollapse = (event: any) => {
        setCollapse(!collapse);
    };
    const filterAssets = useCallback(
        (searchValue: string) => {
            var curAssets = json.features.filter((feature) => {
                return feature["properties"]["name"].toLowerCase().includes(searchValue);
            });
            setFilter(searchValue);
            setAssets(curAssets);
        },
        [setFilter]
    );
    const handleSearch = (event: any) => {
        var searchValue = event.target.value.toLowerCase();
        filterAssets(searchValue);
    };
    useEffect(() => {
        if (filter !== "") {
            filterAssets(filter);
        }
    }, [filter, filterAssets]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classes.title}>Asset List</Typography>
                {collapse ? (
                    <ExpandMore className={classes.icon} onClick={toggleCollapse} />
                ) : (
                    <ExpandLess className={classes.icon} onClick={toggleCollapse} />
                )}
            </div>
            <div style={{ display: collapse ? "none" : "block" }}>
                <div className={classes.centerMiddle}>
                    <Input
                        placeholder="Search..."
                        className={classes.search}
                        disableUnderline={true}
                        onChange={handleSearch}
                        value={filter}
                    />
                </div>
                <div onClick={handleClick}>
                    <ul style={{ paddingTop: 10 }}>
                        {assets.map((feature: any, index: number) => (
                            <li key={"type-" + index}>
                                <Typography className={classes.label} onClick={(e) => handleLabelClick(feature)}>
                                    {feature["properties"]["name"]}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={classes.centerMiddle} style={{ marginBottom: 20 }}>
                    <Button color="primary" variant="contained">
                        View Site Overview
                    </Button>
                </div>
            </div>
        </div>
    );
}
