import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import ModalWindow from "components/ModalWindow";
import ViewerActions from "actions/ViewerActions";
import { ExpandLess, ExpandMore, CallMissed, SkipPrevious, SkipNext } from "@material-ui/icons";

const useStyles = makeStyles({
    root: {},
    header: {
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
        height: 50,
        width: "94%",
    },
    title: {
        fontFamily: "Arial",
        fontSize: 14,
        fontWeight: "bold",
        color: "darkblue",
        paddingLeft: 50,
    },
    icon: {
        width: 18,
        height: 18,
        position: "absolute",
        right: 10,
    },
    return: {
        transform: "rotate(45deg)",
        position: "absolute",
        left: 20,
        cursor: "pointer",
    },
    centerMiddle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    description: {
        padding: 24,
        paddingTop: 10,
        fontFamily: "Arial",
        fontSize: 12,
    },
    list: {
        marginLeft: -20,
    },
    search: {
        position: "relative",
        width: "80%",
        height: 30,
        border: "solid 1px darkblue",
        borderRadius: 5,
        top: 10,
        bottom: 20,
        backgroundColor: "white",
        fontFamily: "Arial",
        fontSize: 14,
        padding: 10,
    },
    paddingButtons: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    button: {
        width: 200,
    },
    page: {
        maxHeight: "90%",
        overflow: "hidden",
    },
    pdfDocument: {
        maxHeight: "90%",
        overflow: "hidden",
    },
    tools: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: 20,
        width: "100%",
        fontFamily: "Arial",
        fontSize: 14,
        marginTop: 10,
        position: "absolute",
        bottom: 10,
        marginBottom: 10,
    },
    toolsIcon: {
        cursor: "pointer",
    },
});

function PDFViewer(props: { file: string }) {
    const classes = useStyles();
    const { file } = props;
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = (pdfDocument: any) => {
        setNumPages(pdfDocument._pdfInfo.numPages);
    };
    const movePage = (step: number) => {
        if (pageNumber + step <= numPages && pageNumber + step > 0) {
            setPageNumber(pageNumber + step);
        }
    };

    return (
        <div style={{ marginTop: 20, height: "100%" }}>
            <div className={classes.pdfDocument}>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} style={{ maxHeight: 740 }}>
                    <Page pageNumber={pageNumber} height={740} className={classes.page} />
                </Document>
            </div>
            <div className={classes.tools}>
                <SkipPrevious onClick={(evt) => movePage(-1)} className={classes.toolsIcon} /> Page: {pageNumber} of{" "}
                {numPages} <SkipNext onClick={(e) => movePage(1)} className={classes.toolsIcon} />
            </div>
        </div>
    );
}

type IProps = {
    feature: any;
    setPage: (pageNum: number) => void;
    setFeature: (feature: any) => void;
    setFilter: (filter: any) => void;
};

export default function AssetTitle(props: IProps) {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);
    const [file, setFile] = useState("");
    const { feature, setPage, setFeature, setFilter } = props;
    const handleClick = (evt: any) => {
        setPage(0);
    };
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        var updateFeature = (feature: any) => {
            setFeature(feature);
            setFilter("");
        };
        ViewerActions.on("setFeature", updateFeature);
        return () => {
            ViewerActions.removeEventListener("setFeature", updateFeature);
        };
    }, [feature, setFilter, setFeature]);
    const toggleCollapse = (event: any) => {
        setCollapse(!collapse);
    };
    const navigate = (fileName: string) => {
        var url = "/" + fileName;
        setFile(url);
    };
    const handleClose = () => {
        setFile("");
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <CallMissed className={classes.return} onClick={handleClick} />
                <Typography className={classes.title}>
                    {feature !== null ? feature["properties"]["name"] : ""}
                </Typography>
                {collapse ? (
                    <ExpandMore className={classes.icon} onClick={toggleCollapse} />
                ) : (
                    <ExpandLess className={classes.icon} onClick={toggleCollapse} />
                )}
            </div>
            <Scrollbars
                autoHeight
                autoHeightMax={680}
                style={{ overflowX: "hidden", display: collapse ? "none" : "block" }}
            >
                <div className={classes.description}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: feature !== null ? feature["properties"]["description"] : "",
                        }}
                    ></div>
                </div>
                <div className={classes.centerMiddle} style={{ paddingBottom: 24 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        disabled={(feature !== null ? feature["properties"]["video"] : "").includes("Not")}
                    >
                        Watch Demo Video
                    </Button>
                </div>
                <div className={classes.centerMiddle} style={{ paddingBottom: 38 }}>
                    <Button
                        color="default"
                        variant="contained"
                        className={classes.button}
                        disabled={(feature !== null ? feature["properties"]["datasheet"] : "").includes("Not")}
                        onClick={(e) => navigate(feature["properties"]["datasheet"])}
                    >
                        View the Datasheet
                    </Button>
                </div>
            </Scrollbars>
            <ModalWindow open={file !== ""} handleClose={handleClose} width={600} height={800}>
                <PDFViewer file={file} />
            </ModalWindow>
        </div>
    );
}
