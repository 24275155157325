import EventEmitter from 'eventemitter3';

export default class EventsEmitter {
	static eventEmitter: any = new EventEmitter();

	static on(eventName: string, listener: any) {
		this.eventEmitter.on(eventName, listener);
	}

	static removeEventListener(eventName: string, listener: any) {
		this.eventEmitter.removeListener(eventName, listener);
	}

	static emit(event: string, payload: any, error = false) {
		this.eventEmitter.emit(event, payload, error);
	}
}
