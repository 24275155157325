import { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import EventsEmitter from "events/EventsEmitter";

export default function ProgressBar() {
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        var setBusy = (status: any) => {
            setIsBusy(status.isBusy);
        };
        EventsEmitter.on("busy", setBusy);
        return () => {
            EventsEmitter.removeEventListener("busy", setBusy);
        };
    });

    return (
        <div>
            {isBusy ? (
                <LinearProgress
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 10000,
                        height: 2,
                    }}
                ></LinearProgress>
            ) : (
                ""
            )}
        </div>
    );
}
