import { useState } from "react";
import Viewer from "./Viewer";
import Panel from "Panel";
import { CircularProgress } from "@material-ui/core";

export default function App() {
    const [isLoaded, setLoaded] = useState(false);
    const onNewScene = () => {
        setLoaded(true);
    };
    return (
        <div>
            <div
                style={{
                    display: isLoaded ? "none" : "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100vh",
                    zIndex: 100000,
                    backgroundColor: "silver",
                }}
            >
                <CircularProgress />
            </div>
            <Panel isLoaded={isLoaded} />
            <Viewer isLoaded={isLoaded} onNewScene={onNewScene} />
        </div>
    );
}
