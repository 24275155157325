import EventsEmitter from "events/EventsEmitter";

export default class ViewerActions extends EventsEmitter {
    static viewer: any;
    static setThumbnailPos(cameraPos: any, focusPos: any) {
        var { viewer } = ViewerActions;
        if (viewer) {
            viewer
                .getViewer()
                .setThumbnailPos(
                    parseFloat(cameraPos[0]),
                    parseFloat(cameraPos[1]),
                    parseFloat(cameraPos[2]),
                    parseFloat(focusPos[0]),
                    parseFloat(focusPos[1]),
                    parseFloat(focusPos[2])
                );
        }
    }

    static getFeature(features: any, id: string) {
        return features.find((feature: any) => feature["properties"]["id"] === id);
    }

    static teleportToGeo(coordinates: any, cameraDist: number) {
        var { viewer } = ViewerActions;
        if (viewer) {
            viewer.getViewer().teleportToGeo(coordinates, cameraDist);
        }
    }

    static geoClick(features: any, id: string) {
        var curFeature = ViewerActions.getFeature(features, id);
        if (curFeature) {
            ViewerActions.emit("setFeature", curFeature);
            var coordinates = curFeature["geometry"]["coordinates"];
            ViewerActions.teleportToGeo(coordinates, 100);
        }
    }

    static navigate(fileName: string) {
        window.open("/" + fileName, "_blank");
    }
}
